function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
}

export function initMapyCzElement($component) {
    const mapId = $component.data('mapycz-map-id')
    const mapType = $component.data('mapycz-map-type')
    const defaultControls = $component.data('mapycz-map-default-controls')
    const enableClusters = $component.data('mapycz-map-enable-clusterer')
    const defaultZoom = $component.data('mapycz-map-default-zoom')
    const centerCoords = $component.data('mapycz-map-center')
    const markers = $component.data('mapycz-map-markers')

    let center = SMap.Coords.fromWGS84(centerCoords.lon, centerCoords.lat);
    let m = new SMap(JAK.gel(mapId), center, defaultZoom);
    m.addDefaultLayer(mapType).enable();

    if (defaultControls) {
        m.addDefaultControls()
    }

    if (markers) {
        let layerMarkers = new SMap.Layer.Marker();
        m.addLayer(layerMarkers);
        layerMarkers.enable();
        $.each(markers, (index, item) => {
            let center = SMap.Coords.fromWGS84(item.lon, item.lat);
            let options = {}
            if (item.title)
                options['title'] = item.title
            if (item.mark)
                options['url'] = createElementFromHTML(item.mark)

            let marker = new SMap.Marker(center, "myMarker", options);
            if (item.card) {
                var card = new SMap.Card();
                card.getHeader().innerHTML = item.card.title;
                card.getBody().innerHTML = item.card.body;
                marker.decorate(SMap.Marker.Feature.Card, card);
            }

            layerMarkers.addMarker(marker);
        })

    }
}

export default function initializeMapyCzComponents() {
    $(() => {
        $('div[data-mapycz-map-id]').each(function () {
            initMapyCzElement($(this));
        })
    })
}

