export default {
    labelIdle: 'Přetáhněte soubor sem (drag&drop) nebo <span class="filepond--label-action"> Vyhledat </span>',
    labelInvalidField: 'Pole obsahuje chybné soubory',
    labelFileWaitingForSize: 'Zjišťuje se velikost',
    labelFileSizeNotAvailable: 'Velikost není známá',
    labelFileLoading: 'Přenáší se',
    labelFileLoadError: 'Chyba při přenosu',
    labelFileProcessing: 'Probíhá upload',
    labelFileProcessingComplete: 'Upload dokončen',
    labelFileProcessingAborted: 'Upload stornován',
    labelFileProcessingError: 'Chyba při uploadu',
    labelFileProcessingRevertError: 'Chyba při obnově',
    labelFileRemoveError: 'Chyba při odstranění',
    labelTapToCancel: 'klepněte pro storno',
    labelTapToRetry: 'klepněte pro opakování',
    labelTapToUndo: 'klepněte pro vrácení',
    labelButtonRemoveItem: 'Odstranit',
    labelButtonAbortItemLoad: 'Storno',
    labelButtonRetryItemLoad: 'Opakovat',
    labelButtonAbortItemProcessing: 'Zpět',
    labelButtonUndoItemProcessing: 'Vrátit',
    labelButtonRetryItemProcessing: 'Opakovat',
    labelButtonProcessItem: 'Upload',
    labelMaxFileSizeExceeded: 'Soubor je příliš velký',
    labelMaxFileSize: 'Největší velikost souboru je {filesize}',
    labelMaxTotalFileSizeExceeded: 'Překročena maximální celková velikost souboru',
    labelMaxTotalFileSize: 'Maximální celková velikost souboru je {filesize}',
    labelFileTypeNotAllowed: 'Soubor je nesprávného typu',
    fileValidateTypeLabelExpectedTypes: 'Očekává se {allButLastType} nebo {lastType}',
    imageValidateSizeLabelFormatError: 'Obrázek tohoto typu není podporován',
    imageValidateSizeLabelImageSizeTooSmall: 'Obrázek je příliš malý',
    imageValidateSizeLabelImageSizeTooBig: 'Obrázek je příliš velký',
    imageValidateSizeLabelExpectedMinSize: 'Minimální rozměr je {minWidth} × {minHeight}',
    imageValidateSizeLabelExpectedMaxSize: 'Maximální rozměr je {maxWidth} × {maxHeight}',
    imageValidateSizeLabelImageResolutionTooLow: 'Rozlišení je příliš malé',
    imageValidateSizeLabelImageResolutionTooHigh: 'Rozlišení je příliš velké',
    imageValidateSizeLabelExpectedMinResolution: 'Minimální rozlišení je {minResolution}',
    imageValidateSizeLabelExpectedMaxResolution: 'Maximální rozlišení je {maxResolution}'
};
