import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";

document.addEventListener('alpine:init', () => {
    // MODAL
    Alpine.bind('OpenModal', (history = false) => ({
        '@click.prevent'() {
            Alpine.store('modal').open()
            naja.makeRequest('GET', this.$el.href, null, {
                history: history
            })
        },
    }))

    Alpine.store('modal', {
        show: false,
        placeholder: false,
        toggle() {
            this.show ? this.close() : this.open()
        },
        open() {
            this.show = true
        },
        close() {
            this.show = false
            setTimeout(() => this.placeholder = true, 500)
        },
        hidePlaceholder() {
            this.placeholder = false
        },
        showPlaceholder() {
            this.placeholder = true
        }
    })

    //Metric Redirect Link
    Alpine.bind('RedirectLink', () => ({
        '@click'(e) {
            let href = new URL(this.$el.getAttribute("href"))

            let redirectLink = new URL('/redirect', window.location)
            redirectLink.searchParams.set('url', href.toString())
            if (this.sid) {
                redirectLink.searchParams.set('sid', this.sid)
            }
            if (this.sub) {
                redirectLink.searchParams.set('sub', this.sub)
            }
            const target = this.$el.getAttribute("target")
            window.open(redirectLink.toString(), target ?? '_self');
            e.preventDefault()
        },
    }))
})

// Start alpinejs
window.Alpine = Alpine
Alpine.plugin(intersect)
Alpine.start()