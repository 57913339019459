import 'regenerator-runtime/runtime'
import axios from "axios";

const EP_FIREBASE_REGISTER_TOKEN = '/firebase/register-token';

export default class Api {

    constructor(baseUrl) {
        let url = baseUrl ? baseUrl : (window.config.API_BASE_URL ? window.config.API_BASE_URL : 'localhost:8080/api/v1')
        this.client = axios.create({
            baseURL: url
        })
    }

    async storeFirebaseRegistrationToken(token) {
        let result = await this.client.post(EP_FIREBASE_REGISTER_TOKEN, {
            token: token
        })
        return result.status === 200
    }
}