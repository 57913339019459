import 'regenerator-runtime/runtime'
import axios from 'axios'
import urljoin from 'url-join'
import {v4 as uuidv4} from "uuid";
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import cs_CZ from 'filepond/locale/cs-cz';


class Server {

    constructor(authEndpoint, deleteEndpoint) {
        this.authEndpoint = authEndpoint
        this.deleteEndpoint = deleteEndpoint
    }

    async auth() {
        if (!this.config) {
            const response = await axios.get(this.authEndpoint)
            this.config = {
                token: response.data.token,
                url: response.data.base_url,
                container: response.data.container
            }
        }
        return this.config
    }

    clearConfig() {
        this.config = null
    }

    load(source, load, error, progress, abort, headers) {
        let cancel
        axios.get(source, {
            mode: "cors",
            responseType: 'blob', // important
            onUploadProgress: (e) => {
                progress(e.lengthComputable, e.loaded, e.total)
            }, cancelToken: new axios.CancelToken((c) => {
                cancel = c
            })
        }).then((response) => {
            let theBlob = response.data
            theBlob.lastModifiedDate = new Date();
            theBlob.name = "loaded.jpeg";
            load(theBlob)
        }).catch((e) => {
            error(e)
        })
        return {
            abort: () => {
                // This function is entered if the user has tapped the cancel button
                cancel && cancel()
                // Let FilePond know the request has been cancelled
                abort();
            },
        };
    }

    async remove(source, load, error) {
        let formData = new FormData()
        formData.append("path", source)
        console.log(this.deleteEndpoint)
        let resp = await axios.post(this.deleteEndpoint, formData)
        if (resp.status === 200) {
            load()
        } else {
            error(resp.data)
        }
    }

    process(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
        let cancel
        const config = this.auth()
        config.then(async (config) => {
            const ext = file.name.split('.').pop()
            const filename = uuidv4() + '.' + ext
            const url = urljoin(config.url, config.container, filename)
            let formData = new FormData()
            formData.append('file', file)
            const response = await axios.post(url, formData, {
                headers: {
                    "Authorization": "Bearer " + config.token
                },
                onUploadProgress: (e) => {
                    progress(e.lengthComputable, e.loaded, e.total)
                },
                cancelToken: new axios.CancelToken((c) => {
                    cancel = c
                })
            })
            load(urljoin(config.container, filename))
        })
        return {
            abort: () => {
                // This function is entered if the user has tapped the cancel button
                cancel && cancel()

                // Let FilePond know the request has been cancelled
                abort();
            },
        };
    }
}

function initializeInputEditable(input, form) {
    let images = []
    let preview = $(input).data('preview')
    let loginURL = $(input).data('login-url')
    let deleteURL = $(input).data('delete-url')
    let elId = $(input).attr('id');
    let maxItems = $(input).data("max-items");
    let defaultImages = $(input).data("images");
    let messageBox = $(input).closest('form').find("#" + elId + '_message');
    let result = {}
    // INIT SERVER
    const srv = new Server(loginURL, deleteURL)
    let files = defaultImages ? defaultImages.map((url) => {
        return {
            source: url,
            options: {
                type: 'local',
            }
        }
    }) : []
    // INIT POND
    FilePond.registerPlugin(FilePondPluginImagePreview)
    FilePond.registerPlugin(FilePondPluginFilePoster)
    FilePond.setOptions(cs_CZ)
    const i = document.getElementById(preview);
    const pond = FilePond.create(i, {
        files: files,
        allowImagePreview: true,
        allowMultiple: true,
        itemInsertLocation: "after",
        acceptedFileTypes: ['image/*'],
        server: {
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                return srv.process(fieldName, file, metadata, load, error, progress, abort, transfer, options)
            },
            load: (source, load, error, progress, abort, headers) => {
                srv.load(source, load, error, progress, abort, headers)
            },
            fetch: (url, load, error, progress, abort, headers) => {
                console.log("FETCH CALLED", url)
            },
            restore: (uniqueFileId, load, error, progress, abort, headers) => {
                console.log("RESTORE CALLED", uniqueFileId)
            },
            remove: (source, load, error) => {
                console.log("REMOVE CALLED")
                srv.remove(source, load, error)
            },
            revert: () => {
                console.log("REVERT", source)
            }
        },
        onerror: (error, file) =>{
            console.error('Error', error, file);
        },
        onwarning:(error, file) => {
            console.warn('Warning', error, file);
        },
        onaddfilestart: (a) => {
            console.log("onaddfilestart")
            // DISABLE SUBMIT
            $(form).find('input[type=submit]').attr('disabled', 'disabled');
            $(form).find('button[type=submit]').attr('disabled', 'disabled');
        },
        beforeRemoveFile: (file) => {
            pond.removeFile(file)
        },
        onaddfile: (er) => {
            console.log("onaddfile")
            $(form).find('input[type=submit]').removeAttr('disabled');
            $(form).find('button[type=submit]').removeAttr('disabled');
        },
        onprocessfilestart: () => {
            console.log("onprocessfilestart")
            // DISABLE SUBMIT
            $(form).find('input[type=submit]').attr('disabled', 'disabled');
            $(form).find('button[type=submit]').attr('disabled', 'disabled');
        },
        onprocessfile: (error, file) => {
            console.log("onprocessfile", file)
            if (error) {
                console.error(error)
                return
            }
            $(form).find('input[type=submit]').removeAttr('disabled');
            $(form).find('button[type=submit]').removeAttr('disabled');
            console.log("RESULT", result)
            result[file.id] = file.serverId
        },
        onprocessfiles: () => {
            console.log("onprocessfiles")
            // Enable submit
            $(form).find('input[type=submit]').removeAttr('disabled');
            $(form).find('button[type=submit]').removeAttr('disabled');
        },
        onremovefile: (error, file) => {
            if (error) {
                console.error(error)
                return
            }
            console.log("onremove")
            delete result[file.id]
        }
    });


    maxItems && pond.setOptions({maxFiles: maxItems})


    $(form).on('submit', () => {
        $(input).val(JSON.stringify(result))
    })

}

function initForm(form) {
    $(form)
        .find('[data-imgsrv]')
        .each((idx, input) => {
            initializeInputEditable(input, form)
        });
}

export default function initNewFileUpload(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initForm(form)
        });
    });

    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initForm(form);
    };
}