// if(document.readyState === 'loading'){
//     window.addEventListener('DOMContentLoaded', afterLoaded);
// } else {
//     afterLoaded()
// }

export class ModalControlExtension {
    constructor() {
    }

    initialize(naja) {

        naja.addEventListener('success', ({detail}) => {
            // console.log(detail);
            // Old style
            let form = $('#' + detail.payload.uid).find('form');
            $('#' + detail.payload.uid).modal({
                show: true,
                focus: !form, //When form is inside modal, focus is handled different way
            })

            if (detail.payload.modalClose || detail.payload.modalHide) {
                $('.modal').modal('hide');
                Alpine.store('modal')?.close()
            }

            // Open modal new system
            // console.log(detail.payload.modalOpen)
            if (detail.payload.modalOpen) {
                let form = $('#' + detail.payload.modalOpen).find('form');
                $('#' + detail.payload.modalOpen).modal({
                    show: true,
                    focus: !form, //When form is inside modal, focus is handled different way
                })
            }
        });
    }
}

export class LoadingIndicatorExtension {
    constructor() {
    }

    initialize(naja) {
        naja.uiHandler.addEventListener('interaction', this.locateLoadingIndicator.bind(this));
        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
    }

    // Prepare element for show loading indicator
    locateLoadingIndicator({detail}) {
        let $element = $(detail.element);
        detail.options.spinnerInjected = false;
        if ($element.data('spinner-selector') !== undefined) {
            detail.options.spinner = $($element.data('spinner-selector'));
        } else if ($element.find('[data-spinner]').length || $element.find('.spinner').length) {
            detail.options.spinner = $element.find('[data-spinner]') || $element.find('.spinner')
        } else if ($element.closest('[data-spinner]').length || $element.closest('.spinner').length) {
            detail.options.spinner = $element.closest('[data-spinner]') || $element.closest('.spinner')
        } else if ($element.siblings('[data-spinner]').length || $element.siblings('.spinner').length) {
            detail.options.spinner = $element.siblings('[data-spinner]') || $element.siblings('.spinner')
        } else if (
            $element.data('spinner-inject') !== undefined ||
            $element.data('ajax-spin') !== undefined ||
            $element.hasClass('ajax-spin')
        ) {
            detail.options.spinner = 'inject'
            detail.options.spinnerInjected = true;

            if ($element.find('svg').length) {
                detail.options.spinnerReplace = $element.find('svg')
            }
            if ($element.find('.fa').length) {
                detail.options.spinnerReplace = $element.find('.fa')
            }
            if ($element.find('.far').length) {
                detail.options.spinnerReplace = $element.find('.far')
            }
            if ($element.find('.fas').length) {
                detail.options.spinnerReplace = $element.find('.fas')
            }
        }
        detail.options.element = $element;
    }

    // Zmenit na "Pracuji..."
    showLoader({detail}) {
        let $element = detail.options.element
        if ($element) {
            if (detail.options.spinner === 'inject') {
                detail.options.spinner = $(`<span>&nbsp;<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>`)
                if (detail.options.spinnerReplace) {
                    detail.options.spinnerReplace.addClass('d-none')
                }

                $element.append(detail.options.spinner);
            }
            $element.attr('disabled', true);
            $element.addClass('disabled')
            if (detail.options.spinner) {
                let $spinner = detail.options.spinner
                $spinner.addClass('visible')
            }
        }
    }

    hideLoader({detail}) {
        let $element = detail.options.element
        if ($element) {
            $element.removeAttr('disabled')
            $element.removeClass('disabled')
            if (detail.options.spinner) {
                let $spinner = detail.options.spinner
                if (detail.options.spinnerInjected) {
                    $spinner.remove()
                    if (detail.options.spinnerReplace) {
                        detail.options.spinnerReplace.removeClass('d-none')
                    }
                } else {
                    $spinner.removeClass('visible')
                }
            }
        }
    }
}