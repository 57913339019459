function initializeSubmitOnChange(form) {
    $(form).change(function () {
        $(this).submit();
    });
}

function initializeForm(form) {
    if ($(form).hasClass('form-submit-on-change')) {
        initializeSubmitOnChange(form)
    }
}

export default function initFormExtensions(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initializeForm(form);
        });
    });

    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initializeForm(form);
    };
}