import Alpine from "alpinejs"
import '../components/jquery'

// import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'

import {LiveForm, Nette} from "live-form-validation";

import initFileUpload from "../components/file_upload";
import naja from '../components/naja'
import 'bootstrap'
import initializeAutocompleteInput from "@nepada/autocomplete-input";
import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'
import {LoadingIndicatorExtension, ModalControlExtension} from "../components/naja_extensions";
import initTextAreaAutosize from "../components/text-area-autosize";
import {initFormFirebaseMessageSwitch, requestNotificationPermission} from '../components/firebase-messaging'

import {initMapyCz, MapyCzExtension} from "../components/mapy_cz/mapy_cz";
import initializeTagManager from '../components/tags'
import initializeCropie from "../components/cropiie";
import RatingNajaExtension from '../components/rating/RatingNajaExtension'
import initNewFileUpload from "../components/new_file_upload";

import '../components/text-area-autosize'
import initFormExtensions from "../components/form-extensions";
import axios from "axios";
import Cookies from 'js-cookie'

import intersect from '@alpinejs/intersect'





window.axios = axios;
window.Cookies = Cookies;
window.Nette = Nette;
window.LiveForm = LiveForm;
// window.Nette = netteForms;
window.jQuery = jQuery;
window.$ = jQuery;

window.requestNotificationPermission = requestNotificationPermission;


// Live form settings
LiveForm.setOptions({
    wait: 1000,
});

// TODO: NAJA IS NOT WORKING WITH FORM AJAX
naja.registerExtension(new ModalControlExtension());
naja.registerExtension(new MapyCzExtension());
naja.registerExtension(new RatingNajaExtension());
naja.registerExtension(new LoadingIndicatorExtension(null));


initializeAutocompleteInput(Nette);
initMapyCz(Nette)
initializeTagManager(Nette, LiveForm)
initFileUpload(Nette)
initTextAreaAutosize(Nette)
initializeCropie(Nette)
initNewFileUpload(Nette)
initFormExtensions(Nette)
initFormFirebaseMessageSwitch(Nette)

naja.formsHandler.netteForms = Nette;
document.addEventListener(
    'DOMContentLoaded',
    () => {
        naja.initialize();
    }
);


// TODO: musime dat do callbacku pro reinit
$('.autosize').autosize({
    // append: "\n",
});

$(document).on('a.disabled', 'click', function (event) {
    // console.log("DISABLED")
    event.preventDefault();
});