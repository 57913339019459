
import Tagify from "@yaireo/tagify";
import Croppie from 'croppie/croppie';
import '../components/scroll-top';
// Css
import '@yaireo/tagify/dist/tagify.css'
import 'croppie/croppie.css'
//https://materializecss.com/icons.html
(function ($) {

    // $('body').on('click', '.submit', () => {
    //     $(this).closest('.form').submit()
    // })
    $('form').each(function() {
        $(this).find('input').keypress(function(e) {
            // Enter pressed?
            if(e.which == 10 || e.which == 13) {
                this.form.submit();
            }
        });
        // $(this).find('input[type=submit]').hide();
    });



    // Init tagify
    $.fn.tagify = function(settings = {}){
        return this.each(function() {
            var $input = $(this),
                tagify;

            if( $input.data("tagify") ) // don't continue if already "tagified"
                return this;

            settings.isJQueryPlugin = true;
            tagify = new Tagify($input[0], settings);
            $input.data("tagify", tagify);
        });
    }

    // Init bootstrap
    // $('body').bootstrapMaterialDesign();

    // Tagify implementation
    $('.tagify')
        .tagify({
            delimiters: ' ',
            maxTags: 30,
            transformTag: function (tag) {
                if(tag.__isValid && !tag.value.match(/^#/g)){
                    tag.value = '#' + tag.value;
                }
                // for(var i = 0; i < this.value.length; i++){
                //     if (this.value[i].value === tag.value){
                //         return '';
                //     }
                // }
                return tag.value
            }
        })
        .on('add', function (e, tagData) {
            console.log('added', tagData)  // data, index, and DOM node
        }).on('invalid', function (e, tagData) {
        console.log('invalid', tagData)  // data, index, and DOM node
    });
}($))

// Save on change
$(document).ready(function () {
    $('body').on('change', 'form.form-save-on-change input, textarea, select', function (e) {
        // Because of bootstrap material design (firing on change permanently)
        if (e.originalEvent) {
            $(this).closest('form.form-save-on-change').submit();
            console.log("submitted!", $(this).closest('form.form-save-on-change'))
        }
    })
});

