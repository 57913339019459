import 'corejs-typeahead/dist/typeahead.jquery';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

function initializeInput(input) {
    const $input = $(input);
    if ($input.is('.tt-input, .tt-hint')) {
        return;
    }
    // let url = 'https://api.mapy.cz/suggest/?count=7&phrase=__QUERY_PLACEHOLDER__&enableCategories=1&lang=cs%2Cen';// $input.data('autocompleteUrl');
    // url = 'https://api.mapy.cz/suggest/?count=7&phrase=__QUERY_PLACEHOLDER__&enableCategories=1&lang=cs%2Cen';// $input.data('autocompleteUrl');
    // const placeholder = $input.data('smapy-placeholder') || '__QUERY_PLACEHOLDER__';
    // const minLength = $input.data('smapy-min-length') || 1;
    // const emptyResultsMsg = $input.data('smapy-empty-results-msg') || 'No results';
    // const autoSelect = $input.data('smapy-auto-select') ? $input.data('smapy-auto-select') : true
    // const highlight = $input.data('smapy-highlight') ? $input.data('smapy-highlight') : true
    const dataResultId = $input.data('smapy-hidden-id')
    const category = $input.data('smapy-category')
    const autocompleteUrl = $input.data('smapy-autocomplete-url')
    // console.log(autocompleteUrl)
    if (category) {
        // url = url + '&category=' + category;
    }
    const $form = $input.closest('form');
    const $inputHidden = $form.find('#' + dataResultId);

    var suggest = new SMap.Suggest($input.get(0));
    suggest.urlParams({
        // omezeni pro celou CR
        // bounds: "48.5370786,12.0921668|51.0746358,18.8927040"
    });
    suggest.addListener("suggest", function(suggestData) {
        console.log(suggestData)
        // vyber polozky z naseptavace
        setData($inputHidden, suggestData);
    })



    // let defaultValue = $input.val()
    // let suggester = new Bloodhound({
    //     queryTokenizer: Bloodhound.tokenizers.whitespace,
    //     datumTokenizer: Bloodhound.tokenizers.whitespace,
    //     remote: {
    //         url: autocompleteUrl,
    //         wildcard: placeholder,
    //         transform: function (params) {
    //             //TODO: add this into data-filter-category
    //             return params.result.map(transformAddress).filter((itm) => {
    //                 let item = itm.original
    //                 if (category) {
    //                     return item.category === category;
    //                 }
    //                 return item.category !== 'poiC'
    //                     && item.category !== 'poi_cz'
    //                     && item.category !== 'poi_osm'
    //                     && item.category !== 'pubtran_cz'
    //                     && item.category !== 'pubtran_osm';
    //             })
    //         },
    //     },
    // });
    // suggester.initialize();
    // $input.typeahead(
    //     {
    //         highlight: highlight,
    //         autoselect: autoSelect,
    //         minLength: minLength,
    //     },
    //     {
    //         display: 'suggest',
    //         source: suggester.ttAdapter(),
    //         templates: {
    //             suggestion: function ({_query, suggest, desc, sec}) {
    //                 if (sec) {
    //                     return `<div><strong>${suggest}</strong><p><small>${desc}</small></p><p><small>${sec}</small></p></div>`
    //                 } else {
    //                     return `<div><strong>${suggest}</strong><p><small>${desc}</small></p></div>`
    //                 }
    //             },
    //             empty: function () {
    //                 return `<div>${emptyResultsMsg}</div>`
    //             }
    //         }
    //     }
    // ).bind('typeahead:select', function (ev, suggestion) {
    //     setData($inputHidden, suggestion.original)
    // });


    $form.on('submit', (e) => {
        // if inputHidden is empty => was written not selected! Because of this i can think, that user don't want to change location
        // if (!$inputHidden.val() && $input.val() && defaultValue !== $input.val()) {
        //     // console.log('fetching data')
        //     let fetch = suggester.__ttAdapter ? suggester.__ttAdapter() : suggester;
        //     fetch($input.val(), () => {
        //     }, (found) => {
        //         if (found && found.length) {
        //             setData($inputHidden, found[0].original)
        //         }
        //         $form.submit()
        //     })
        //     e.preventDefault()
        //     return false;
        // }
        // console.log('submit true')
        // return true;
    })

    // $input.on('keypress', () => {
    //     setData($inputHidden, null)
    // })

    //Remove duplicity prevent from data validation
    $input.removeAttr('data-smapy-duplicity')
    $form.find('input[data-smapy-duplicity]').each(function () {
        $(this).removeAttr('data-nette-rules')
    })
}

function setData($hiddenInput, data) {
    $hiddenInput.val(data && JSON.stringify(data) || null);
}

function transformAddress(item) {
    // console.log(item)
    return {
        suggest: item.userData.suggestFirstRow,
        desc: item.userData.suggestSecondRow,
        sec: item.userData.suggestThirdRow,
        original: item,
    };
}


function initializeForm(form) {
    $(form)
        .find('input[data-smapy-hidden-id]')
        .each((idx, input) => {
            initializeInput(input)
        });
}

export default function initializeMapyCzSuggest(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initializeForm(form);
        });
    });

    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initializeForm(form);
    };
}
