// Init croppie
import Croppie from "croppie";

$(() => {
    $.fn.croppie = function (opts) {
        var ot = typeof opts;

        if (ot === 'string') {
            var args = Array.prototype.slice.call(arguments, 1);
            var singleInst = $(this).data('croppie');

            if (opts === 'get') {
                return singleInst.get();
            } else if (opts === 'result') {
                return singleInst.result.apply(singleInst, args);
            } else if (opts === 'bind') {
                return singleInst.bind.apply(singleInst, args);
            }

            return this.each(function () {
                var i = $(this).data('croppie');
                if (!i) return;

                var method = i[opts];
                if ($.isFunction(method)) {
                    method.apply(i, args);
                    if (opts === 'destroy') {
                        $(this).removeData('croppie');
                    }
                } else {
                    throw 'Croppie ' + opts + ' method not found';
                }
            });
        } else {
            return this.each(function () {
                var i = new Croppie(this, opts);
                i.$ = $;
                $(this).data('croppie', i);
            });
        }
    };
});


function initCropieForm($form) {

    var $preview = $form.find('.avatar-preview');
    var uploadCrop = $preview.croppie({
        url: '/public/images/no-profile.png',
        enableExif: true,
        viewport: {
            width: 250,
            height: 250,
            type: 'circle'
        },
        boundary: {
            width: 300,
            height: 300
        }
    })

    $form.find('input[type=file]').on('change', function () {
        if (this.files && this.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                // preview.addClass('ready');
                uploadCrop.croppie('bind', {
                    url: e.target.result
                }).then(function () {
                    console.log('jQuery bind complete');
                });
            }
            reader.readAsDataURL(this.files[0]);
        } else {
            console.error("Sorry - you're browser doesn't support the FileReader API");
        }
    });
    $form.on('submit', function () {
        uploadCrop.croppie('result', {
            type: 'rawcanvas', //base64, blob
            size: 'viewport',
        }).then(function (resp) {
            $form.find('input[name=base64]').val(resp.toDataURL());
        });
        return true
    });
    $form.data('croppie-attached', true)
}

function initializeForm(form) {
    if ($(form).is('.croppie-form') && !$(form).data('croppie-attached')) {
        initCropieForm($(form))
    }
}

//MUST BE RUNNER BEFORE NETTE INIT FORM!!!
export default function initializeCropie(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initializeForm(form);
        });
    });
    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initializeForm(form);
    };
}