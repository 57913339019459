import './suggester'
import initializeMapyCzSuggest from "./suggester";
import initializeMapyCzComponents, {initMapyCzElement} from './component'

export class MapyCzExtension {
    constructor(defaultLoadingIndicatorSelector) {
    }

    initialize(naja) {
        naja.snippetHandler.addEventListener('afterUpdate', this.locateLoadingIndicator.bind(this));
    }

    locateLoadingIndicator({detail}) {
        $(detail.snippet).find('div[data-mapycz-map-id]').each(function () {
            initMapyCzElement($(this))
        })
    }
}

export function initMapyCz(Nette) {
    initializeMapyCzSuggest(Nette)
    initializeMapyCzComponents()
}