function createTag(key, label) {
    return $(
        `<div class="badge badge-pill badge-secondary p1 ml-2" data-tag-key="${key}">
            <span class="p-3">${label}</span>
            <a href="#" data-close-tag>
                <span class="fa fa-close text-white text-decoration-none"></span>
            </a>
        </div>`
    );
}

export function initTagManager(input, Nette, LiveForm) {
    let $input = $(input);
    const tagDataId = $input.data('tags-data-id');
    // const maxTags = $input.data('tags-max-items') || null;

    let tags = {}
    let tagIndex = 0;
    let $tagContainer = $($input.data('tags-container'));
    if (!$tagContainer.length) {
        $tagContainer = $(`<div class="tags-container"></div>`)
        $input.parent().append($tagContainer)
    }
    let $tagData = $(tagDataId)
    // SHOW what is inside
    let val = $(tagDataId).val()
    if (val) {
        if (val.charAt(0) === ',') {
            val = val.substring(1);
        }
        let items = val.split(',')
        if (items.length) {
            for (let i = 0; i < items.length; i++) {
                let value = items[i]
                let key = ++tagIndex
                let $tag = createTag(key, value)
                tags[key] = {value: value, $el: $tag}
                $tagContainer.append($tag)
                $tag.find('[data-close-tag]').on('click', function (e) {
                    let key = $(this).closest('[data-tag-key]').data('tag-key')
                    let tag = tags[key]
                    delete tags[key]
                    $(tag.$el).remove()

                    let values = Object.values(tags);
                    $tagData.val(values.map((itm) => itm.value))
                    e.preventDefault()
                    return false
                })
            }
        }
    }
    // Udelat to tak, ze v pripade, ze uz nemuzu pridatavat, tak pri zmacknuti enter (napisu ze byl vycerpan max limit)
    // Pri kazdem pridani upravuji message pro pridavani :) Bude to pres setOption v nette, kde bude prazdno a pres data si pridam placeholder + message??? 
    $input.on('keypress', function (e) {
        if (e.keyCode === 13) {
            processNewTag(this)
            e.preventDefault()
            e.stopPropagation()
            // Nette.addError(input, "FUCK YOU")
            return false
        }
    })

    $input.on('focusout', function () {
        LiveForm.removeError(input)
    })

    $input.closest('form').on('submit', function (e) {
        if (processNewTag(input)) {
            e.preventDefault()
            setTimeout(function () {
                $input.closest('form').submit();
            }, 500);
        }
    })

    function processNewTag(input) {
        // CHECK
        let value = $(input).val();
        if (!value) {
            return false;
        }
        if (
            Nette.validateControl(input, undefined, undefined, undefined, undefined, true)
            // (!maxTags || Object.keys(tags).length + 1 <= maxTags)
            // true
        ) {
            $(input).val(null);

            let key = ++tagIndex
            let $tag = createTag(key, value)

            tags[key] = {value: value, $el: $tag}
            $tagContainer.append($tag);
            let values = Object.values(tags);
            $tagData.val(values.map((itm) => itm.value))

            $tag.find('[data-close-tag]').on('click', function (e) {
                let key = $(this).closest('[data-tag-key]').data('tag-key')
                let tag = tags[key]
                delete tags[key]
                $(tag.$el).remove()

                let values = Object.values(tags);
                $tagData.val(values.map((itm) => itm.value))
                e.preventDefault()
                return false
            })
        }
        $input.on('change', function () {
            $input.data('tags-submitted', false)
        })
        return true;
    }

}

function initializeForm(form, Nette, LiveForm) {
    $(form)
        .find('input[data-tag-manager]')
        .each((idx, input) => {
            initTagManager(input, Nette, LiveForm)
        });
}

//MUST BE RUNNER BEFORE NETTE INIT FORM!!!
export default function initializeTagManager(Nette, LiveForm) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initializeForm(form, Nette, LiveForm);
        });
    });
    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initializeForm(form, Nette, LiveForm);
    };
    let originalValidateControl = Nette.validateControl
    Nette.validateControl = function (elem, rules, onlyCheck, value, emptyOptional, forceValidations) {
        // Skip validation if is tags
        if (forceValidations) {
            return originalValidateControl(elem, rules, onlyCheck, value, emptyOptional)
        }
        return $(elem).is('input[data-tag-manager]') || originalValidateControl(elem, rules, onlyCheck, value, emptyOptional);
    }

    // Validace poctu prvku zde je pruser, protoze jsem schopen zkontrolovat, jen to co je pres limit
    Nette.validators.AppExtensionsTagManagerTagDataInput_validateMaxTags = function (elem, arg, value) {
        let $input = $(elem)
        const tagDataId = $input.data('tags-data-id');
        const maxTags = $input.data('tags-max-items') || null;
        let val = $(tagDataId).val();
        if (val.charAt(0) === ',') {
            val = val.substring(1)
        }
        let items = val && val.split(',')
        // console.log(items, val)
        return !items || items.length + 1 <= maxTags
    };

    Nette.validators.AppExtensionsTagManagerTagDataInput_validateDups = function (elem, arg, value) {
        let $input = $(elem)
        const tagDataId = $input.data('tags-data-id');
        let items = $(tagDataId).val().split(',')
        return !items || !items.includes(value)
    };
}