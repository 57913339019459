// Pond
function readMultipleURL(input, callback) {
    if (input.files) {
        for (let i = 0; i < input.files.length; i++) {
            let reader = new FileReader();
            reader.onload = function (e) {
                callback(e.target.result)
            }
            reader.readAsDataURL(input.files[i]); // convert to base64 string
        }
    }
}

function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return file && $.inArray(file['type'], acceptedImageTypes)
}


function initializeInputEditable(input) {
    // IF IS NOT DEFINED ERROR
    var preview = $(input).data('preview')
    var inputHidden = $(input).data('store')
    var images = []
    var maxItems = $(input).data('max-items');
    var maxItemsMessage = $(input).data('max-items-message');
    
    $(input).change(function () {
        var self = this
        if (!preview) {
            console.error("Preview container is not set")
            return
        }
        readMultipleURL(this, (file) => {
            let elId = $(self).attr('id');
            let messageBox = $(self).closest('form').find("#"+elId+'_message');
            if(images.length >= maxItems) {
                messageBox.text(maxItemsMessage);
                messageBox.addClass('text-danger')
                return;
            } else {
                messageBox.text("");
                messageBox.removeClass('text-danger')
            }
            let img = $('<img>')
            img.attr('src', file)
            img.attr('class', 'rounded img-responsive img-fluid')

            let remove = $('<a class="remove-item fa fa-remove text-danger" href="#">')

            // TODO: allow remove
            remove.on('click', (event) => {
                block.remove()
                images = images.filter((image) => {
                    return image !== file
                })
                event.stopImmediatePropagation()
                event.stopPropagation()
                if (inputHidden) {
                    $('#' + inputHidden).val(JSON.stringify(images))
                }
                if(images.length <= maxItems) {
                    messageBox.text("");
                    messageBox.removeClass('text-danger')
                }
            })

            let block = $('<div class="upload-preview-item col-4 pb-3">')
            block.append(img)
            // TODO: remove button
            block.append(remove)
            $('#' + preview).append(
                block
            );
            images.push(file)
            if (inputHidden) {
                $('#' + inputHidden).val(JSON.stringify(images))
            }
        });
        // Clear upload files (if any image is too big)
        $(this).val('')
    });
}

function initializeInput(input) {
    $(input).change(function () {
        // IF IS NOT DEFINED ERROR
        var preview = $(this).data('for')
        $('#' + preview).empty()
        var self = this
        if (!preview) {
            console.error("Preview container is not set")
            return
        }
        readMultipleURL(this, (file) => {
            let img = $('<img>')
            img.attr('src', file)
            img.attr('class', 'rounded img-responsive')

            let block = $('<div>')
            block.append(img)
            $('#' + preview).append(
                block
            );
        });
    });
}

function initializeForm(form) {
    $(form)
        .find('.upload-with-preview')
        .each((idx, input) => {
            initializeInput(input)
        });
    $(form)
        .find('[data-image-upload]')
        .each((idx, input) => {
            console.log(input);
            initializeInputEditable(input)
        });
}

export default function initFileUpload(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initializeForm(form);
        });
    });

    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initializeForm(form);
    };
}