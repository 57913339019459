import {initializeApp} from "@firebase/app";
import {getMessaging, getToken} from "@firebase/messaging";
import {getAnalytics} from "@firebase/analytics";
import Api from './api.js'

export function requestNotificationPermission(baseUrl, success = null) {
    // Import the functions you need from the SDKs you need

    // Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 (current version 7.4) and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyCuP9p5xzvrcdUxXMqXwaEszDD_9Uoz3VE",
        authDomain: "profilidi-e6be6.firebaseapp.com",
        projectId: "profilidi-e6be6",
        storageBucket: "profilidi-e6be6.appspot.com",
        messagingSenderId: "204378115072",
        appId: "1:204378115072:web:ee9f792d86358be35c1ce5",
        measurementId: "G-BCXV84Y4SS"
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, {
        vapidKey: 'BC1w57ITFRTe9L31uSdiHbNoURsp33Ra840IwR49wpVESvzSrTqcNJsOQuppMYuSFQ7_tI5P5-j41U-r9GpcYP8'
    }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            let api = new Api(baseUrl);
            if (!api.storeFirebaseRegistrationToken(currentToken)) {
                console.log("Failed store current token");
            }
            if (success) {
                success(currentToken);
            }
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

function initializeFirebaseMessage(input, form) {
    $(input).change(function () {

        let v = $(input).is(':checked')
        $(input).prop('checked', v);
        let baseUrl = $(input).data('base-url') || $(form).data('base-url');
        console.log(baseUrl)
        if (v === true) {
            requestNotificationPermission(baseUrl, () => {
                $(form).submit();
            })
        } else {
            $(form).submit();
        }
    });
}

function initForm(form) {
    if (!$(form).data('init-firebase-message')) {
        $(form)
            .find('.enable-firebase-message')
            .each((idx, input) => {
                initializeFirebaseMessage(input, form)
            });
        $(form).data('init-firebase-message', true)
    }
}

export function initFormFirebaseMessageSwitch(Nette) {
    // Initialize all forms on document ready
    $(() => {
        $('form').each((idx, form) => {
            initForm(form);
        });
    });
    // Tap into Nette.initForm() to provide AJAX snippet support via e.g. Naja
    const originalInitForm = Nette.initForm;
    Nette.initForm = (form) => {
        originalInitForm(form);
        initForm(form);
    };
}
