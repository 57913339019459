import 'bootstrap-star-rating'
import 'bootstrap-star-rating/css/star-rating.css'
import './rating.scss'

function initRatingInput($input) {
    $input.rating({
        filledStar: '<span class="fa fa-star"></span>',
        emptyStar: '<span class="fa fa-star"></span>',
        showCaption: false,
        showCaptionAsTitle: false,
        step: 1
    });
}

function initialize(){
    $('input[data-rating-control]').each(function () {
        initRatingInput($(this))
    });
}

export default class RatingNajaExtension {
    constructor(init=true) {
        init && initialize();
    }

    initialize(naja) {
        naja.snippetHandler.addEventListener('afterUpdate', this.initRating.bind(this));
    }

    initRating({detail}) {
        $(detail.snippet).find('input[data-rating-control]').each(function () {
            initRatingInput($(this))
        })
    }
}
