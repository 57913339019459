// import 'bootstrap-material-design/dist/js/bootstrap-material-design';
import './components/alpine'
import './modules/common'
import './modules/front_custom'
import './components/file_upload'

// main imports
import './front.scss'

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})


// import './front.css'
